import { useRef, type FC, type ReactNode } from 'react';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Button } from './ui/button';
import { Close as DialogClose } from '@radix-ui/react-dialog';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';

type ConfirmDialogProps = {
	asChild?: boolean;
	children?: ReactNode;
	text: string;
	actionButtonText: string;
	cancelButtonText?: string;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	onConfirm?: () => void;
};
const ConfirmDialog = (props: ConfirmDialogProps) => {
	const {
		asChild,
		open,
		onOpenChange,
		onConfirm,
		children,
		text,
		actionButtonText,
		cancelButtonText,
	} = props;

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			{!!children && (
				<DialogTrigger asChild={asChild}>{children}</DialogTrigger>
			)}
			<DialogContent
				noClose
				title="Confirm action"
				className="flex flex-col items-center justify-center gap-8 py-8"
			>
				<p>{text}</p>
				<div className="flex flex-col gap-1">
					<Button
						variant="gradient"
						className="font-semibold"
						onClick={e => {
							onOpenChange?.(false);
							onConfirm?.();
						}}
					>
						{actionButtonText}
					</Button>

					<DialogClose asChild>
						<Button variant="link" className="text-muted-foreground opacity-60">
							{cancelButtonText ?? 'Cancel'}
						</Button>
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	);
};

type GlobalConfirmDialogProps = {
	actionButtonText?: string;
	cancelButtonText?: string;
	text: string;
	onConfirm: () => void;
	onCancel?: () => void;
};

const useGlobalConfirmDialogStore = create(
	combine(
		{
			dialogProps: null as GlobalConfirmDialogProps | null,
		},
		(set, get) => ({
			open: (dialogProps: GlobalConfirmDialogProps) => set({ dialogProps }),
			close: () => set({ dialogProps: null }),
		}),
	),
);

export const GlobalConfirmDialog: FC = () => {
	const currentProps = useGlobalConfirmDialogStore(state => state.dialogProps);
	const lastNonNullProps = useRef<GlobalConfirmDialogProps | null>(null);

	if (currentProps) {
		lastNonNullProps.current = currentProps;
	}

	const props = lastNonNullProps.current;

	const close = useGlobalConfirmDialogStore(state => state.close);
	return (
		<ConfirmDialog
			open={!!currentProps}
			onOpenChange={open => {
				if (!open) {
					currentProps?.onCancel?.();
					close();
				}
			}}
			onConfirm={() => {
				currentProps?.onConfirm();
				close();
			}}
			text={props?.text ?? ''}
			actionButtonText={props?.actionButtonText ?? 'Confirm'}
			cancelButtonText={props?.cancelButtonText ?? 'Cancel'}
		/>
	);
};

export function requireConfirmationDialog(props: GlobalConfirmDialogProps) {
	useGlobalConfirmDialogStore.getState().open(props);
}
